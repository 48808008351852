export var tableField = [
  { selectType: 'selection', width: '', type: 'input' },
  { label: '序号', code: '', type: 'function', width: '', handle (index) { return index + 1 } },
  { label: '采购单编号', code: 'orderCode', type: 'input', width: '110' },
  { label: '采购单行号', code: 'rowProject', type: 'input', width: '110' },
  { label: '物料编号', code: 'materialCode', type: 'input', width: '110' },
  { label: '物料描述', code: 'materialDescribe', type: 'input', width: '' },
  { label: '工厂', code: 'plant', type: 'input', width: '' },
  { label: '开票数量', code: 'orderNum', type: 'input', width: '' },
  { label: '未税单价', code: 'noTaxPrice', type: 'amount', align: 'right', width: '110' },
  { label: '含税单价', code: 'taxPrice', type: 'amount', align: 'right', width: '110' },
  { label: '含税金额', code: 'taxTotalPrice', type: 'amount', align: 'right', width: '' },
  { label: '币种', code: 'currencyType', type: 'input', width: '' },
  {
    label: '本次预付金额',
    code: 'prepayMoney',
    type: 'vnode',
    width: '130',
    handle (scope) {
      if (scope) {
        return {
          props: ['changeList'],
          data: function () {
            return {
              rowData: { ...scope.row }
            }
          },
          created () {
            this.changeList.data[scope.$index] = this
            this.rowData.prepayMoney = this.rowData.taxTotalPrice
          },
          methods: {
            tChange () {
              this.$emit('apChange')
            }
          },
          template: `<span>
                <el-input @input="tChange" v-model="rowData.prepayMoney" placeholder="请输入金额"></el-input>
          </span>`
        }
      }
    }
  }
]

export var tableFieldDt = [
  { selectType: 'selection', width: '', type: 'input' },
  { label: '采购单编号', code: 'orderCode', type: 'input', width: '110' },
  { label: '采购单行号', code: 'rowProject', type: 'input', width: '110' },
  { label: '物料编号', code: 'materialCode', type: 'input', width: '110' },
  { label: '物料描述', code: 'materialDescribe', type: 'input', width: '' },
  { label: '工厂', code: 'plant', type: 'input', width: '' },
  { label: '开票数量', code: 'orderNum', type: 'input', width: '' },
  { label: '未税单价', code: 'noTaxPrice', type: 'amount', align: 'right', width: '110' },
  { label: '含税单价', code: 'taxPrice', type: 'amount', align: 'right', width: '110' },
  { label: '含税金额', code: 'taxTotalPrice', type: 'amount', align: 'right', width: '' },
  { label: '币种', code: 'currencyType', type: 'input', width: '' },
  {
    label: '本次预付金额',
    code: 'prepayMoney',
    type: 'input',
    width: '130'
  }
]

export var tableData = []
export var formData = {
  accountsPayableDetailList: [
  ],
  bankAccount: '',
  billMoney: 0,
  bz: '',
  company: '',
  createDate: '',
  file: [],
  createUserId: '',
  createUserName: '',
  currency: '',
  prepayDetailList: [
  ]
}
export var currencyOption = [
  { label: 'RMB', value: '1' },
  { label: 'USD', value: '2' }
]
