<template>
  <div class="mainform">
    <!-- 创建预付款申请 -->
    <div class="mainHeader">创建预付款申请<span @click="back" class="back">返回></span></div>
    <div class="form">
      <div class="header">付款内容</div>
      <el-form ref="fkform" class="content bascform" :model="form" label-width="140px">
        <div class="col col4">
          <el-form-item label="公司" prop="company" :rules="{ required: true, message: '请选择公司', trigger: 'blur' }">
            <el-select size="small" filterable remote
              :remote-method="requestCompanyOption"
              :loading="companyLoading"
              @change="comChange"
               v-model="form.company">
              <el-option v-for="(item) in companyOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="col col4">
          <el-form-item label="供应商" prop="supplier" :rules="{ required: true, message: '请选择供应商', trigger: 'blur' }">
            <el-select size="small" filterable remote
              :remote-method="requestSupplierOption"
              :loading="supplierLoading"
              @change="supChange"
               v-model="form.supplier">
              <el-option v-for="(item) in supplierOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="col col4">
          <el-form-item label="币种" prop="currency" :rules="{ required: true, message: '请选择币种', trigger: 'blur' }">
            <el-select v-model="form.currency">
              <el-option :key="index" v-for="(itme,index) in currencyOption" :label="itme.label" :value="itme.label">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
         <div class="col col4" style="vertical-align: bottom;">
          <el-form-item label="申请原因及备注" prop="bz" :rules="{ required: true, message: '请输入申请原因及备注', trigger: 'blur' }">
            <el-input v-model="form.bz"></el-input>
          </el-form-item>
        </div>
         <div class="col col4" style="vertical-align: top;">
          <el-form-item label="附件">
            <el-upload ref="upload" name="file"
            :auto-upload="true" :action='postUrl'
            :show-file-list="true"
            :on-exceed="handleExceed"
            :on-remove="handleRemove"
            :before-remove="beforeRemove"
            :on-success="handSuccess"
            :on-error="handError"
            :headers="tokenHeader"
            :file-list="form.file">
              <el-button size="small" type="primary" plain>+上传</el-button>
            </el-upload>
          </el-form-item>
        </div>
        <div class="col col4">
          <el-form-item label="申请总额">
            <span class="onlyText">{{form.totalMoney}}</span>
          </el-form-item>
        </div>
      </el-form>
      <div class="header">付款明细</div>
      <el-form class="bascform prepayDetailList" label-width="80px">
        <div class="col col8">
          <el-form-item label="明细">
            <el-button size="small" type="primary" plain @click="addDetail">+选择明细</el-button>
          </el-form-item>
        </div>
        <div class="col col8">
          <el-form-item>
            <global-table @apChange="apChange" :tableField="tableField" :tableData="form.prepayDetailList" :changeList="changeList"></global-table>
          </el-form-item>
        </div>
      </el-form>
      <dialog-box ref="addDialog" dialogWidth="60%" :dialogShow="dialogShow" @handleClose='handleClose'
        @confirmAdd="confirmAdd" title="添加明细" componentName="CreateaplanPayreqdialog" :defaultSearchData='searchData'
        :buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' },{label:'确认',action:'confirmAdd', type:'primary', size:'mini'}]">
      </dialog-box>
    </div>
    <div class="action">
      <el-button class="submit" type="primary" size="medium" @click="submit">提交</el-button>
    </div>
  </div>
</template>

<script>

import Component from '@/assets/js/components.js'
import axios from 'axios'
import { tableField, tableData, formData, currencyOption } from './js/createaplanPayreq'
import { baseURL, request, requestForm, certificationForm, deepClone } from '@/assets/js/http.js'
import { FlowCtlData } from '@/assets/js/FlowManage.js'
export default {
  components: Component.components,
  name: 'CreateaplanPayreq',
  data () {
    FlowCtlData.flowid = 'PaymentApproval'
    FlowCtlData.docid = ''
    FlowCtlData.initialize()
    return {
      tableField: tableField,
      tableData: tableData,
      currencyOption: currencyOption,
      dialogShow: false,
      supplierOptions: [],
      companyOptions: [],
      companyLoading: false,
      supplierLoading: false,
      searchData: {},
      form: deepClone(formData),
      changeList: { data: [] },
      tokenHeader: {
        token: localStorage.getItem('token')
      },
      postUrl: `${baseURL}/api/file/m/file/uploadToServer`
    }
  },
  created () {
    FlowCtlData.getApprPsn('551625315191681024').then((val) => { this.form.buyerLeader = val })
    FlowCtlData.getApprPsn('561052621739134976').then((val) => { this.form.accountant = val })
    this.form.currency = 'RMB'
    const id = this.$route.query.id
    if (id) {
      request('/api/reconciliation/pay/getByPayOrderId/' + id, 'get').then((res) => {
        if (res.code === '200') {
          this.form = res.data
          FlowCtlData.getApprPsn('551625315191681024').then((val) => { this.form.buyerLeader = val })
          FlowCtlData.getApprPsn('561052621739134976').then((val) => { this.form.accountant = val })
        }
      })
    }
  },
  watch: {
    $route: {
      handler () {
        const id = this.$route.query.id
        if (id) {
          request('/api/reconciliation/pay/getByPayOrderId/' + id, 'get').then((res) => {
            if (res.code === '200') {
              this.form = res.data
              FlowCtlData.getApprPsn('551625315191681024').then((val) => { this.form.buyerLeader = val })
              FlowCtlData.getApprPsn('561052621739134976').then((val) => { this.form.accountant = val })
            }
          })
        }
      }
    }
  },
  methods: {
    apChange () {
      this.form.totalMoney = 0.00
      for (const item of this.changeList.data) {
        this.form.totalMoney = this.form.totalMoney + (parseFloat(item.rowData.prepayMoney) || 0)
      }
      this.form.totalMoney = (this.form.totalMoney).toFixed(2)
    },
    requestSupplierOption (val) {
      this.supplierLoading = true
      this.supplierOptions = []
      requestForm('/api/supplier/basicData/getForSelect', 'post', { supplier: val }).then((Response) => {
        if (Response.code === '200') {
          for (const item of Response.data) {
            this.supplierOptions.push({
              key: `${item.name}`,
              label: `${item.name}-${item.erpCode}`,
              value: `${item.name}`,
              erpCode: `${item.erpCode}`
            })
          }
        }
        this.supplierLoading = false
      })
    },
    comChange (data) {
      for (const item of this.companyOptions) {
        if (item.value === data) {
          this.form.companyCode = item.companyCode
        }
      }
    },
    supChange (data) {
      for (const item of this.supplierOptions) {
        if (item.value === data) {
          this.form.supplierCode = item.erpCode
        }
      }
    },
    requestCompanyOption (val) {
      this.companyLoading = true
      this.companyOptions = []
      axios.post(`${baseURL}/api/sap/getCompanyCodeInfo`, val, {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          token: localStorage.getItem('token')
        }
      }).then((Response) => {
        if (Response.data.code === 200) {
          for (const item of Response.data.data) {
            this.companyOptions.push({
              key: `${item.companyName}`,
              label: `${item.companyName}-${item.companyCode}`,
              value: `${item.companyName}`,
              companyCode: `${item.companyCode}`
            })
          }
        } else {
          this.$message({
            message: Response.data.msg,
            type: 'error'
          })
        }
        this.companyLoading = false
      })
    },
    submit () { // 提交创建预付款申请单
      if (certificationForm(this, 'fkform')) {
        FlowCtlData.formAlert(this, 'error', '请将表单填写完整！')
        return false
      }
      const lcobj = {}
      lcobj.buyerLeader = this.form.buyerLeader
      lcobj.accountant = this.form.accountant
      const obj = {
        ...this.form
      }
      obj.prepayDetailList = []
      let prepayDetailPass = true
      for (const item of this.changeList.data) {
        if (!item.rowData.prepayMoney || item.rowData.prepayMoney === '') {
          prepayDetailPass = false
        }
        obj.prepayDetailList.push(item.rowData)
      }
      if (!prepayDetailPass) {
        this.$message({
          message: '请将本次预付金额填写完整！',
          type: 'error'
        })
        return false
      }
      const tempspr = obj.accountant
      FlowCtlData.getApprPsn('', `${obj.companyCode}-公司【付款审批】`).then((val) => {
        obj.accountant = val
        lcobj.accountant = val
        if (obj.accountant === '') {
          obj.accountant = tempspr
          lcobj.accountant = tempspr
        }
        obj.sfsp = 'y'
        lcobj.sfsp = 'y'
        FlowCtlData.getNodeAction('TJ', lcobj).then((val) => {
          if (val) {
            FlowCtlData.alertDialog(this).then(() => {
              FlowCtlData.setFormData(FlowCtlData, lcobj)
              obj.status = 1
              const id = this.$route.query.id
              let url = '/api/reconciliation/pay/savePrepay'
              if (id) {
                url = '/api/reconciliation/pay/updatePrepay'
              }
              request(url, 'post', obj).then((Response) => {
                if (Response.code === '200') {
                  FlowCtlData.docid = Response.data
                  FlowCtlData.setNodeAction().then((val) => {
                    FlowCtlData.formAlert(this, 'success')
                    this.$router.push({ path: '/FinancialManagement/PaymentFind' })
                  })
                }
              })
            }).catch(() => { FlowCtlData.formAlert(this, 'info') })
          }
        }).catch(() => { FlowCtlData.formAlert(this, 'error') })
      })
    },
    confirmAdd () { // 添加明细弹框的确认按钮
      var newListData = this.$refs.addDialog.$refs.content.$refs.addDetailtable.selectData
      console.log('newListData', newListData)
      if (newListData.length > 0) {
        this.form.prepayDetailList = this.$refs.addDialog.$refs.content.$refs.addDetailtable.selectData
        this.form.totalMoney = 0.00
        for (const item of this.form.prepayDetailList) {
          this.form.totalMoney = this.form.totalMoney + (parseFloat(item.taxTotalPrice) || 0)
        }
        this.form.totalMoney = (this.form.totalMoney).toFixed(2)
        this.dialogShow = false
      } else {
        this.$message({
          message: '请选择要添加的明细',
          type: 'warning'
        })
      }
    },
    handleClose () {
      this.dialogShow = false
    },
    addDetail () {
      var isPass = true
      console.log('00097654', this.$refs.fkform)
      this.$refs.fkform.validate((valid) => {
        if (!valid) {
          isPass = false
        }
      })
      if (!isPass) {
        this.$message({
          message: '请将信息填写完整',
          type: 'error'
        })
        return false
      }
      this.searchData = { company: this.form.companyCode, supplier: this.form.supplierCode, currency: this.form.currency }
      this.dialogShow = true
    },
    back: () => {
      window.history.back()
    },
    // 判断是否只能上传一个文件，超过提示报错
    handleExceed (e) {
      this.$notify.error({
        title: '错误',
        message: '只能上传一个文件'
      })
    },
    // 验证上传文件格式
    // handleChansge (file, fileList) {
    //   if (!/\.(xlsx|xls|XLSX|XLS)$/.test(file.name)) {
    //     this.$notify.error({
    //       title: '错误',
    //       message: '上传文件只能为excel文件，且为xlsx,xls格式'
    //     })
    //     return false
    //   }
    // },
    // 上传失败提示
    handError () {
      this.$notify.error({
        title: '错误',
        message: '文件上传失败'
      })
    },
    handleRemove (file) {
      for (var i = 0; i < this.form.file.length; i++) {
        if (this.form.file[i].id === file.id) {
          this.form.file.splice(i, 1)
        }
      }
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    // 上传成功或错误提示
    handSuccess (response, file, fileList) {
      if (response.code === '200') {
        response.data.forEach(element => {
          this.form.file.push(element)
        })
      }
    }
  }
}
</script>
<style scoped lang="scss">
  @import '@/assets/css/elForm.scss';
  @import '@/assets/css/tableList.scss';

  .detail {
    color: #666;
    font-size: 16px;
    padding: 0px 30px;
  }

  .detailspanBtn {
    padding-bottom: 20px;
  }

  .prepayDetailList {
    .tableList {
      padding: 0 0px;
    }
  }
</style>
